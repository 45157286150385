import {
  getProperty as _getProperty,
  setProperty as _setProperty,
} from 'dot-prop'
import type { IManagerFormValue } from '@manager/types'
import { ManagerFormValueSymbol } from '~/layers/manager'

export const useManagerFormValue = <
  T extends IManagerFormValue = IManagerFormValue,
>() => {
  const formValue = inject(ManagerFormValueSymbol) as Ref<T>
  const getProperty = (path: string): unknown => {
    if (!path) return
    return _getProperty(formValue.value, path)
  }
  const setProperty = (path: string, value: unknown): unknown => {
    if (!path) return
    return _setProperty(formValue.value, path, value)
  }

  return {
    formValue,
    getProperty,
    setProperty,
  }
}
